'use client'
import clientGetCookies from '@jobmojito/admin-app/components/core/clientCookies'
import RegisterMain from '@jobmojito/admin-app/components/registration/RegisterMain'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
  ModalHeader,
  Card,
} from '@nextui-org/react'
import NextImage from 'next/image'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import chevronDown from './MenuLinks/assets/chevron_down.svg'

type MerchantType = {
  name: string
  id: string
  status: string
}

export const dynamic = 'force-dynamic'
export default function MerchantDropdown({
  xMojito,
}: {
  xMojito: HeaderMerchantProfile
}) {
  const supabase = getClientSupabase()
  const router = useRouter()
  const searchParams = useSearchParams()
  const search = searchParams.get('reload')

  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const [loaded, setLoaded] = useState<boolean>(false)
  const [showCreationModal, setShowCreationModal] = useState<boolean>(false)
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [listMerchants, setListMerchants] = useState<
    MerchantType[] | undefined
  >()
  const [selectedMerchant, setSelectedMerchant] = useState<
    MerchantType | undefined
  >()

  useEffect(() => {
    if (search !== undefined && search !== null) {
      if (typeof window !== 'undefined') window.location.href = '/'
    }

    if (
      xMojito.profile?.type === 'admin' ||
      (xMojito.profile.sub_merchant_id !== undefined &&
        xMojito.profile.sub_merchant_id !== null)
    ) {
      supabase
        .from('merchant')
        .select('name, status, id')
        .in('status', ['draft', 'active'])
        .order('name')
        .then(({ data: merchants, error: merchantsError }) => {
          if (merchants !== undefined && merchants !== null && !loaded) {
            setLoaded(true)
            setListMerchants(merchants)
            setSelectedMerchant(
              merchants.find(
                (m) =>
                  m.id ===
                  (clientGetCookies('set_merchant_id') ||
                    xMojito.merchant?.id ||
                    ''),
              ),
            )
          }
        })
    }

    if (
      xMojito.profile?.type !== 'admin' &&
      (xMojito.profile.sub_merchant_id === null ||
        xMojito.profile.sub_merchant_id === undefined) &&
      clientGetCookies('set_merchant_id')
    ) {
      router.push('/?set_merchant_id=delete')
    }
  }, [search])

  if (
    xMojito.profile?.type === 'admin' ||
    (xMojito.profile.sub_merchant_id !== undefined &&
      xMojito.profile.sub_merchant_id !== null)
  ) {
    // hard reload to get cookies up and running
    return (
      <>
        {loaded && listMerchants ? (
            <Card isPressable shadow='none' radius='none' className='text-left' onPress={onOpen}>
              <div className={'flex flex-row cursor-pointer'}>
                <div className="flex flex-col">
                  <div className="text-xs">Merchant</div>
                  <div
                    className="text-black text-md underline-offset-4 font-bold cursor-pointer text-nowrap"
                    role="button"
                  >
                    {selectedMerchant ? selectedMerchant.name : 'Not Selected'}
                  </div>
                </div>
                <NextImage
                  src={chevronDown}
                  alt={'dropdown'}
                  className={'h-7 w-7 ms-1 mt-1 self-start'}
                />
              </div>
            </Card>
        ) : (
          <div>
            <Spinner size="sm" className="mr-4" />
          </div>
        )}
        <Modal
          size={'5xl'}
          className="bg-white"
          isOpen={showCreationModal}
          onClose={() => {
            setShowCreationModal(false)
          }}
          isDismissable={false}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody className="m-2">
                  <RegisterMain
                    subMerchant={true}
                    name={
                      xMojito.profile.name === null
                        ? undefined
                        : xMojito.profile.name
                    }
                    email={xMojito.profile.email}
                  />
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>

        {listMerchants &&
        <Modal 
        backdrop="opaque" 
        isOpen={isOpen} 
        size='3xl'
        scrollBehavior='inside'
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-black">Switch merchant</ModalHeader>
              <ModalBody>
                <ul className="flex flex-row gap-2 flex-wrap text-medium text-black">
                  {listMerchants.map((m) => {
                    return (
                      <li key={'merchant-' + m.id}>
                        <Button
                          onClick={() => {
                            router.push('/?set_merchant_id=' + m.id)
                          }}
                          variant="light"
                          size="md"
                          className={
                            'w-56 ' +
                            (m.status === 'active'
                              ? xMojito.profile.merchant_id === m.id
                                ? 'font-bold'
                                : 'text-black'
                              : 'text-stone-400')
                          }
                        >
                          {m.name}
                        </Button>
                      </li>
                    )
                  })}
                  <li key={'merchant-new'}>
                    <Button
                      onClick={() => {
                        setShowPopover(false)
                        setShowCreationModal(true)
                      }}
                      variant="light"
                      color="secondary"
                      size="md"
                      className={'w-full'}
                    >
                      + New Sub Merchant
                    </Button>
                  </li>
                </ul>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      }

      </>
    )
  }
  return <></>
}

'use client'
import MenuLinks from '@jobmojito/admin-app/components/navigation/MenuLinks/MenuLinks'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import { NavbarContent, Navbar as NextUiNavbar } from '@nextui-org/navbar'
import { useState } from 'react'
import {
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon
} from '@heroicons/react/24/solid'
import { Button } from '@nextui-org/react'

export default function Sidebar({
  xMojito,
}: {
  xMojito: HeaderMerchantProfile
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }
  const [showFull, setShowFull] = useState<boolean>(false)

  return (
    <div className={"min-h-fit flex-none hidden lg:flex print:!hidden "+(showFull?'w-64':'w-24')}>
      <NextUiNavbar
        id={'sidebar'}
        className={
          'hidden lg:flex text-left mt-8 relative z-20 fixed top-0 left-0 z-5 w-8 h-screen items-center'
        }
        maxWidth='lg'
        classNames={{ wrapper: 'max-md:px-3' }}
        onMenuOpenChange={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
      >
        <div className='relative bg-gradient-to-br from-secondary to-primary rounded-xl'>
          <div className='absolute -top-4 -right-4'>
            <Button isIconOnly size='sm' className='bg-white' onClick={()=> { setShowFull(!showFull) }}>{!showFull?<ArrowRightCircleIcon className='text-secondary w-4 h-4' />:<ArrowLeftCircleIcon className='text-secondary w-4 h-4' />}</Button>
          </div>
          <NavbarContent className={"flex flex-col grow py-4 px-1 items-start gap-1 "+(showFull?'w-60':'w-20')}>
            <MenuLinks onClick={closeMenu} xMojito={xMojito} showFull={showFull}/>
          </NavbarContent>
        </div>
      </NextUiNavbar>
    </div>
  )
}

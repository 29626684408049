'use client'
import { Tooltip, Chip } from '@nextui-org/react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React from 'react'

export default function MenuLinkItem({
  href,
  children,
  icon,
  className = '',
  showFull,
  notification,
  notificationColor,
  isActive = false,
  onClick = () => {},
}: {
  href: string
  children: React.ReactNode
  showFull: boolean
  icon: React.ReactNode
  notification?: React.ReactNode
  notificationColor?: 'danger' | 'warning' | 'success'
  className?: string
  isActive?: boolean
  onClick?: () => void
}) {
  const router = useRouter()
  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  return (
    (showFull ?
    <Link
      className={'w-full relative px-3 text-gray-700 hover:text-black hover:rounded-lg hover:bg-gray-100 p-1 ' + (isActive?'bg-white text-secondary rounded-lg':'text-white') + className}
      href={href}
      onClick={() => handleLink(event, href)}
    >
      <div className='flex flex-row gap-2 text-sm font-light items-center'>
        <div className='w-6 h-6 '>{icon}</div>
        <div className='flex grow'>{children}</div>
        {notification && <div className={'flex min-w-5 h-5 text-center text-white right-4 text-xs px-1 rounded-xl flex items-middle content-center '+(notificationColor?'bg-'+notificationColor:'bg-danger')}>{notification}</div> }
      </div>
    </Link>
    :
    <Tooltip placement='right' showArrow content={children}>
      <Link
        className={'w-full relative px-3 text-gray-700 hover:text-black hover:rounded-lg hover:bg-gray-100 p-1 ' + (isActive?'bg-white text-secondary rounded-lg':'text-white') + className}
        href={href}
        onClick={() => handleLink(event, href)}
      >
        <div className='w-6 h-6 '>{icon}</div>
        {notification && <div className={'absolute flex min-w-5 -top-1 h-5 text-white text-center -right-1 text-xs px-1 rounded-xl flex items-middle content-center '+(notificationColor?'bg-'+notificationColor:'bg-danger') }>{notification}</div> }
      </Link>
    </Tooltip>
  ))
}

'use client'
import { getMerchantFeatures } from '@jobmojito/admin-app/components/merchantFeatures/getMerchantFeatures'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import MenuLinkItem from '@jobmojito/admin-app/components/navigation/MenuLinks/MenuLinkItem'
import { usePathname } from 'next/navigation'
import React from 'react'
import IsPath from '@jobmojito/app/utils/data/platformLanguagesIsPathClient'
import {
  HomeIcon,
  ArrowTrendingUpIcon,
  ChartBarIcon,
  UserGroupIcon,
  BanknotesIcon,
  AdjustmentsHorizontalIcon,
  BookOpenIcon,
  IdentificationIcon,
  InboxStackIcon,
  PaperAirplaneIcon,
  RectangleStackIcon,
  SwatchIcon,
  TicketIcon,
  UsersIcon,
  RocketLaunchIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { Divider, } from '@nextui-org/react'
import { KpiInterview } from '@jobmojito/admin-app/types/collection'
import { useState, useEffect } from 'react'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { useTranslations } from 'next-intl'

export default function MenuLinks({
  onClick,
  showFull,
  xMojito,
}: {
  onClick: () => void
  showFull: boolean
  xMojito: HeaderMerchantProfile
}) {
  const pathname = usePathname()

  const merchantFeatures = getMerchantFeatures(
    xMojito?.merchant?.merchant_features,
  )

  const [kpi, setKpi] = useState<KpiInterview | undefined>()
  const [loaded, setLoaded] = useState<boolean>(false)
  const t = useTranslations('component-admin-menu')

  useEffect(() => {
    if (!loaded) {
      if (merchantFeatures.interview_portal) {
        const supabase = getClientSupabase()
        supabase
          .rpc('merchant_credits_interview', {
            in_merchant_id: xMojito.merchant.id,
          })
          .then((res) => {
            if (res.data !== null) {
              setKpi(res.data as KpiInterview)
            }
            setLoaded(true)
          })
      }
    }
  }, [xMojito, merchantFeatures])

  const menuSections = [
    { id: 'main', title: '' },
    { id: 'interview', title: t('Interviews') },
    { id: 'white', title: t('Coaching portal') },
    { id: 'affiliate', title: t('Affiliate') },
    { id: 'admin', title: t('Configuration') },
  ]

  let menuItems = [{ href: '/', label: t('Dashboard'), type: 'main', icon: <HomeIcon /> }]
  if (merchantFeatures.affiliate) {
    menuItems = [
      ...menuItems,
      {
        href: '/affiliate_promote_pages',
        label: t('Promote JobMojito'),
        type: 'main',
        icon: <ArrowTrendingUpIcon />
      },
    ]
  }
  menuItems = [
    ...menuItems,
    { href: '/analytics', label: t('Analytics'), type: 'main', icon: <ChartBarIcon /> },
  ]

  if (merchantFeatures.affiliate || merchantFeatures.consumer_portal) {
    if (
      xMojito?.profile?.type === 'admin' ||
      xMojito?.profile?.type === 'merchant_owner'
    ) {
      menuItems = [
        ...menuItems,
        { href: '/commission', label: t('Commissions'), type: 'main', icon: <BanknotesIcon /> },
      ]
    }
  }

  if (merchantFeatures.consumer_portal || merchantFeatures.interview_portal) {
    menuItems = [
      ...menuItems,
      {
        href: '/portal_customisation',
        label: t('Branding & Configuration'),
        type: 'admin',
        icon: <AdjustmentsHorizontalIcon />
      },
    ]

    if (
      merchantFeatures.interview_portal ||
      merchantFeatures.consumer_sponsorship
    ) {
      menuItems = [
        ...menuItems,
        { href: '/avatars', label: t('Avatars'), type: 'admin', icon: <SwatchIcon /> },
        {
          href: '/knowledge_base',
          label: t('Knowledge base'),
          type: 'admin'
          , icon: <BookOpenIcon />
        },
      ]
    }

    if (
      xMojito?.profile?.type === 'admin' ||
      xMojito?.profile?.type === 'merchant_owner'
    ) {
      menuItems = [
        ...menuItems,
        { href: '/admin_users', label: t('Admin Users'), type: 'admin', icon: <UserGroupIcon /> },
      ]
    }

    if (
      merchantFeatures.interview_portal &&
      (xMojito?.profile?.type === 'admin' ||
        xMojito?.profile?.type === 'merchant_owner')
    ) {
      menuItems = [
        ...menuItems,
        {
          href: '/interview_subscription',
          label: t('Subscription & Payments'),
          type: 'admin',
          icon: <TicketIcon />
        },
      ]
    }
  }

  if (merchantFeatures.consumer_portal) {
    menuItems = [
      ...menuItems,
      { href: '/coaching_creator', label: t('Coaching Creator'), type: 'white', icon: <InboxStackIcon /> },
      {
        href: '/coaching_catalogue',
        label: t('Coaching Catalogue'),
        type: 'white',
        icon: <RectangleStackIcon />
      },
    ]
    menuItems = [
      ...menuItems,
      { href: '/coaching_users', label: t('Users'), type: 'white', icon: <IdentificationIcon /> },
    ]
    if (merchantFeatures.consumer_sponsorship) {
      menuItems = [
        ...menuItems,
        { href: '/sponsorship', label: t('Users Sponsorship'), type: 'white', icon: <UsersIcon /> },
      ]
    }
  }

  if (merchantFeatures.interview_portal) {
    menuItems = [
      ...menuItems,
      {
        href: '/interview_creator',
        label: t('Interviews'),
        type: 'interview'
        , icon: <InboxStackIcon />
      },
      { href: '/candidates', label: t('Candidates'), type: 'interview', icon: <IdentificationIcon /> },
      {
        href: '/interview_results',
        label: t('Results'),
        type: 'interview'
        , icon: <PaperAirplaneIcon />
      },
    ]
  }

  return (
    <div>
      {menuSections.map((section) => {
        if (
          menuItems.find((element) => element.type === section.id) !== undefined
        ) {
          return (
            <div key={section.id} className='flex flex-row gap-1 items-end w-full'>
              <div className='relative transform -rotate-90 whitespace-nowrap h-full mb-4 text-white font-light text-xs w-4 items-end'>
                {section.title || ' ' }
              </div>
              <div className='flex flex-col gap-1 w-full'>
              {section.title && <Divider className='bg-white mt-1' /> }
              {menuItems.map((link) => {
                const isActive = IsPath(pathname, link.href)
                let notification = undefined;
                let color: 'danger' | 'warning' | 'success'|undefined = undefined;
                if (link.href==='/interview_subscription' && (xMojito.merchant.interview_subscription_status==='free' || xMojito.merchant.interview_subscription_status==='canceled')) {
                  if (loaded)
                  if (kpi && kpi?.credits_interview_monthly===0) {
                    notification = <ExclamationTriangleIcon className='w-4 h-4' />;
                    color='danger';  
                  } else {
                    notification = <RocketLaunchIcon className='w-4 h-4' />;
                    color='warning';  
                  }
                }
                if (link.type === section.id) {
                  return (
                    <MenuLinkItem
                      isActive={isActive}
                      key={section.id + '-' + link.href.replaceAll('/', '')}
                      href={link.href}
                      icon={link.icon}
                      notification={notification}
                      notificationColor={color}
                      showFull={showFull}
                      onClick={onClick}
                    >
                      {link.label}
                    </MenuLinkItem>
                  )
                }
              })}
            </div>
            </div>
          )
        }
      })}
    </div>
  )
}
